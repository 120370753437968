import React from 'react'

const PageTitle = ({ children }) => {
  return (
    <div className="text-center mb-8">
      <h1 className="page-title font-bold text-center text-5xl">{children}</h1>
    </div>
  )
}

export default PageTitle
