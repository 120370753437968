import React from 'react'
import { DefaultLayout } from '../components/Layouts'
import Meta from '../components/Meta'
import PageTitle from '../components/PageTitle'

const PGP = () => {
  return (
    <DefaultLayout>
      <Meta title="PGP Key" />
      <div className="max-w-xl mx-auto">
        <div className="text-center mb-12">
          <PageTitle>Stephy's PGP Key</PageTitle>
          <a
            href="https://keybase.io/stephy"
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Verify on Keybase
          </a>
        </div>
        {/* prettier-ignore */}
        <pre class="public-key whitespace-pre-line select-all overflow-x-scroll">
          {`-----BEGIN PGP PUBLIC KEY BLOCK-----
          Version: Keybase OpenPGP v2.0.58
          Comment: https://keybase.io/crypto

          xsFNBFf7uhoBEADDAl5YMnVjokdSlMr1Sc6dT0Uf+YU1r/5KDTFr77YJ8PUau3Gi
          KE5/1wrJ6avsfZB7c6pjAjinGXNP8oW9keStn0HKyUShsEpCYq6Rgij8M3bkitp4
          +kMvTCtUzVeSMBCrsR7O6GJIVjAyNNGMyO29VDKRaAsCNi3jvfljdrY2rju5jGIW
          cCKSMMBrWPmVt0Ag94HJ8xLFPRiPQvGSIuzGGC4SC5RVFUS3aPMh+M9wcGjXo07a
          tEZK5xNenImDb/2kBNBorIncS1O8jztBV+B23hBp6hhnL29GDJhM/+4lg5fI4QB4
          jPVQj7/CO1BBqHgE+f4j5Zu975cka+h18Sor1L+kTAk1DitcOuxSPflMQz/MJqu7
          nYaBUG6/i3QZLr/shpgnTfKrrJfq+6/NuXquzCCdmKe1FJL2qmI9FN6uF7JB4Y6A
          Exh/PPPxWpVtGD5vT9Th3jaxF/pgDDbaF0KTYEO5JGy/dyuELnuikdVYCsVz9vLY
          oUHna2v85gjNQFLeX6OJwtGFp9nKalkT3rbNuFRc8QDrLAg1mXT+07HkrRta+DXc
          csYJM1Xe91U75roepAwSCHgNzSk3HKcDl/SDHDbEv5QEXA4ZKq57Iy/JEnYHOTbP
          fbsiP7h2EL/qKHUycPrtI6vW9tBg/iKfuguubawa6e6F5moVfD6ysHtZxwARAQAB
          zShTdGVwaHkgTWllaGxlIDxzdGVwaHlAYmxpbmRpbmdzdGFycy5jb20+wsF0BBMB
          CgAeBQJX+7oaAhsDAwsJBwMVCggCHgECF4ADFgIBAhkBAAoJEMSZlQF/XV/ZS18Q
          ALjVoUikaoFZb1nit71iY2etzXIMaH0zmBdL/iJraaiCSEpuC8v1qLSwGMq1QS0E
          BVYGm7LCwDRT1mOorYMUOka8zy+Ko0e2gJ3SGuwbwu4F+TLi5VfFYeP7Bc2Q71Vr
          2g1xSxtf7E37qt+hjbW+wiKpAK4KHBMruPXtQmcOv6bug2yXY0xORQSHcFMtQOCo
          H5iwg+j2MA53L429mu6hBtlyRJ+PMq2HNWm6FXO5DUu4QsF6huPbNv1aCWmUeG/Y
          CGemAKLLhhNWoHQ4iljdh9VjUBQIvSytQmJeU8bY7mLjF6XJ2u6Z45XoC87fQkno
          OjKNQ/MCw15PudOl4nn/JfIemH+2xEJgiWgIdtgx6e4COY5ZXXsPpbkyk5pkE8EE
          dr/URfgz8KKW/YI4Tj5GhsmiwUCj0f4C6axae9gHUIEbjyrjTYUmQuwlJSXidEIr
          mof2RhjIbWuRLX+AS+TLirznOy+LQxmHMeOjGxTCZwdQMILPMELoWf28oOwhGEA3
          r2QimLN/sDmJGRs/B7gJhzAyDuplMfK1zMQ9QvVwQiJ7Z2Gi4ZBQw/kdJ6a5I/Sg
          Jym9gIPElG/Ad8n9Xk1Aoh2ZPdGlN/Nyn4o12iSdL7JzUY2nAnPbxRb9PulGygBw
          zmIQ8hRPg/R3WC/tMEUNTqA+fLXa6TvYXasUHY76gnjgzsBNBFf7uhoBCACucstg
          jHXeuyHEIjGai+o1Rv2X38sLxAHB9NJ2rVXnidCjqYjMNJIdQUIws6DZnNVU4ff+
          x2OaYgIAv/DiwMGDnyrmFQm1nSshUWDNJsO39D0G1bvDSZlpwSEFJClZ7pR9ASCY
          8yiBvyFqjejKcWsliqQ6OvygF5jieFGURg9KHhrQIckuzz/jxgq6uMcCYCyKp7sa
          Nx56jaIrxJD4QkbnACA1+wsCCXRb1wi0SAXcfzY3L6jkndxXJgQXirtpW9IdN1tv
          fNyd3kjij+TVhyhgNk3jGiknkdmb9ehezgz6mdhn2gbGzf/hnMRYXy8v09RC9Z7T
          J3Pk30/9lRfuEsppABEBAAHCwoQEGAEKAA8FAlf7uhoFCQ8JnAACGwwBKQkQxJmV
          AX9dX9nAXSAEGQEKAAYFAlf7uhoACgkQr/+MkvyW+AIySgf+MufNfEHhKlrOox3C
          hTTxZmLW1cK9P47LuyeE9iAaGYdA3vW3+RJ+FL0eaOTJ0TpEMXj/i3Lv3GQQgaxB
          kBbFBnJdmZUSH7hVdG0T5qhRtqNkK53teeUb5VYZEWT/qIYUtXE8As+k+ZR3j92e
          Mgqxq9u/PCM+o0f6H/MnKf0Cka7EofLAv1BRIAhJPvgGag74TAoowYELzAnMbB5Q
          Gta1XfxZIyYwlVplwl7r3mjGehDFFwv0N3+orv6fwrrBnsk5obbbHe1mD0q+DFsd
          A3okOJchGnsquTlmtYV8D0sIgcgxAxmV88dFIAwOaEtzOQgAMkscfCM/zjcyZXOH
          Q352/c25EACLbS7IgHS34vTIVWAI7bOSa9dNaf0OmudE2JprhH5IS9CQlIRzHUHn
          F9bjr9WqzyuzqNI1aJZvSRr0Lo/e4hFkcHhSKtHiaR10QQ6VomriW1HJccbZ+pZ3
          kraYOHIPxEIK38tGw2GL0ufZhx6TVLRKzNzGNCYCKh3+vvhZW3QHrxw9mFv4YBvi
          0bCi/l05XUXuhxtQeAaA8zB8vFXozofJ6yggAkg1FPa3g3V7/odQkCQMwG8hX9i+
          cX/UlpkXWymyG8vWh261kah2uUdFs0w6sdMQmv/ZiuHMsTyZgX1KZe7zp3sHM2mA
          SNV+kurkv15xj8U78jZzXZnVUbPUAPuG2SbQ8SuAEA8hsxq3u4VVwAnaE7sHrdsx
          jbHa5mEDOWl/efUscIvEQ7e5D7AGq+lMss7FP1bvooYB+mMxXQRIePsGDgRmgFo7
          MS55pvwdLOoWkinWZChaAMdkccYxCmSUQlYlihhqQLjWYSDbTvt0HKZgLDLoEJ+y
          qJkw8Rs6SZaCmqkLn0VX7PAVk6PBAdU+iQDSDjwC1ChgJ545dAw0kh1vQE2js7rK
          imNUYf2qtdW8gy9/ICMw+dDX8T1hcwZOK3kobs/yZvV0Cu89mdmDC/Dm+B8Oyjm4
          Q92Bt1kfU8Mma3HZ/guXXwYQkfyOCcjAc8hDEcy2WD7YwzYOe6PdOc7ATQRX+7oa
          AQgAzdKBlSR7BSuw/fzLyRotHMG+SZjkMGIgXGTnfOmyE3IGLi3LESAOn8j8zl41
          t+QbMm7EVT2h0JV8OpwWQDsyZqDkeJMbHR625n+8+aYB4ACwqlg1vPU9jPccRdP5
          wsUb2HnWp9VEB9WQNFyfAtspe3cjQldLtmxU8PCJ59hNvRUY2B5WVe0l5EIDADj6
          ozoeeuJNrmY7f8ptKHyJLTi6O8iLu6z3/sd47wvFIm3G/Qmuk9ErxdiRKpPKljzo
          6sbcrOnEMiNRq1F3rCn4ltUe5WI50vJXbjAUqISGMFe/iXEgGJuLudTVF+E7f6aT
          sNWaMDCDC/4Zfz403wXIRKFDjwARAQABwsKEBBgBCgAPBQJX+7oaBQkPCZwAAhsi
          ASkJEMSZlQF/XV/ZwF0gBBkBCgAGBQJX+7oaAAoJEI9ukN4PBAScC8gH/2CKd4g+
          j7+alkJclqUbNgicy1Z0Vj8zcozzARHVOTKUbTYiolHu5kyJktAFVKK2Hb+mKHZn
          q3Eolz5+JDpTJaQmcNlKHWfBsHrndxeH9YjTj2mOts4fHXje+iwOq8QpkKdo9fR+
          J3M0R8UqN/enzcKZNi6/IZZ9+x9ZU4NVrf28rkDJTJ24kRw1oxaDLWBLW6PBp93l
          cgezWBghcboaHC7UPaoX6jbACHy87yDsGysXh4xrVJPYDJOFFp7WFYJU1D8DN+Iw
          MZ9zw+mF+aAuQyzTX0Bb7UGHlr1hrY4tgKC4F7Cw2xAFEXV/+cKYrK7UVO1QdGsT
          RK+2sTpexka5md+yAg//el/mDhruuEOoK2wra8afixO4gK27XZJgwwqV87tnhDxO
          hJd+nC/7k1aICRgwNP057EBRyqOLTVnHsXTKuHzWn301eaMTKe5JuHSzp4ZCeQzp
          jNzNVD4Z2p/jAl+18EFdIN9Bd+/tU0rtT6y3fTgmt2+QL3nZvfYDV5nVRYaBdWSU
          lZFV/3Rg0M8dOkNoClS1MMycdRM4tXRGWygRsOEco6sl/zdn9++XSecduGxCojEd
          Y14aogDW13aLNmB16ii+Gzgl6h0d4pmSc+L51Tt7yDLzBB2BK7P8sVEDX+7YYwAn
          wWMYgX3Vc1NLEtxyhfp2mLfqyFu+U3c//6ftc6R3qEVq0fdYyyT0NnqP9iL+KPDK
          ww5Q+vo/P+mDbMUTVOARHQ4Xq+LvGJTXJM/0weAxVwnmPjp23sX33ZcEzHbXYoT0
          Zbbfh+/NQs9ZdwU2iX1YyDrA05GzhiN6cbPCHEm+E0NZghOuz7allgY9Yl1DA3sh
          L/8Q58lLQU8IjkHQ1UdTpePft7rPusXz1U8CK/k5L9QgmctBlv56AX5+4VUgf/dl
          YcLjAOJUiM5vxroajFCEEFmEKb6jtc3wUJQwzCr2ZErAWHGaCodJ9Q9FjRFptumV
          jaEfotFouWSnYdecAtSWTBjjHty5YlKwz63rMZUwT0lQ9q7QfFrfogmRcUA89D4=
          =XvTP
          -----END PGP PUBLIC KEY BLOCK-----`}
        </pre>
      </div>
    </DefaultLayout>
  )
}

export default PGP
